@import './colors.css';

/* raleway-regular - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: url('./Fonts/raleway-v12-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Raleway'), local('Raleway-Regular'),
    url('./Fonts/raleway-v12-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    url('./Fonts/raleway-v12-latin-regular.woff2') format('woff2'),
    url('./Fonts/raleway-v12-latin-regular.woff') format('woff'),
    url('./Fonts/raleway-v12-latin-regular.ttf') format('truetype'),
    url('./Fonts/raleway-v12-latin-regular.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-italic - latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 400;
  src: url('./Fonts/raleway-v12-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Raleway Italic'), local('Raleway-Italic'),
    url('./Fonts/raleway-v12-latin-italic.eot?#iefix')
      format('embedded-opentype'),
    url('./Fonts/raleway-v12-latin-italic.woff2') format('woff2'),
    url('./Fonts/raleway-v12-latin-italic.woff') format('woff'),
    url('./Fonts/raleway-v12-latin-italic.ttf') format('truetype'),
    url('./Fonts/raleway-v12-latin-italic.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-700 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  src: url('./Fonts/raleway-v12-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Raleway Bold'), local('Raleway-Bold'),
    url('./Fonts/raleway-v12-latin-700.eot?#iefix') format('embedded-opentype'),
    url('./Fonts/raleway-v12-latin-700.woff2') format('woff2'),
    url('./Fonts/raleway-v12-latin-700.woff') format('woff'),
    url('./Fonts/raleway-v12-latin-700.ttf') format('truetype'),
    url('./Fonts/raleway-v12-latin-700.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-700italic - latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 700;
  src: url('./Fonts/raleway-v12-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local('Raleway Bold Italic'), local('Raleway-BoldItalic'),
    url('./Fonts/raleway-v12-latin-700italic.eot?#iefix')
      format('embedded-opentype'),
    url('./Fonts/raleway-v12-latin-700italic.woff2') format('woff2'),
    url('./Fonts/raleway-v12-latin-700italic.woff') format('woff'),
    url('./Fonts/raleway-v12-latin-700italic.ttf') format('truetype'),
    url('./Fonts/raleway-v12-latin-700italic.svg#Raleway') format('svg'); /* Legacy iOS */
}

/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('./Fonts/roboto-v18-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Roboto Light'), local('Roboto-Light'),
    url('./Fonts/roboto-v18-latin-300.eot?#iefix') format('embedded-opentype'),
    url('./Fonts/roboto-v18-latin-300.woff2') format('woff2'),
    url('./Fonts/roboto-v18-latin-300.woff') format('woff'),
    url('./Fonts/roboto-v18-latin-300.ttf') format('truetype'),
    url('./Fonts/roboto-v18-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('./Fonts/roboto-v18-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto'), local('Roboto-Regular'),
    url('./Fonts/roboto-v18-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    url('./Fonts/roboto-v18-latin-regular.woff2') format('woff2'),
    url('./Fonts/roboto-v18-latin-regular.woff') format('woff'),
    url('./Fonts/roboto-v18-latin-regular.ttf') format('truetype'),
    url('./Fonts/roboto-v18-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('./Fonts/roboto-v18-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Roboto Medium'), local('Roboto-Medium'),
    url('./Fonts/roboto-v18-latin-500.eot?#iefix') format('embedded-opentype'),
    url('./Fonts/roboto-v18-latin-500.woff2') format('woff2'),
    url('./Fonts/roboto-v18-latin-500.woff') format('woff'),
    url('./Fonts/roboto-v18-latin-500.ttf') format('truetype'),
    url('./Fonts/roboto-v18-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: url('./Fonts/roboto-v18-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Roboto Italic'), local('Roboto-Italic'),
    url('./Fonts/roboto-v18-latin-italic.eot?#iefix')
      format('embedded-opentype'),
    url('./Fonts/roboto-v18-latin-italic.woff2') format('woff2'),
    url('./Fonts/roboto-v18-latin-italic.woff') format('woff'),
    url('./Fonts/roboto-v18-latin-italic.ttf') format('truetype'),
    url('./Fonts/roboto-v18-latin-italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: url('./Fonts/roboto-v18-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'),
    url('./Fonts/roboto-v18-latin-700italic.eot?#iefix')
      format('embedded-opentype'),
    url('./Fonts/roboto-v18-latin-700italic.woff2') format('woff2'),
    url('./Fonts/roboto-v18-latin-700italic.woff') format('woff'),
    url('./Fonts/roboto-v18-latin-700italic.ttf') format('truetype'),
    url('./Fonts/roboto-v18-latin-700italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('./Fonts/roboto-v18-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Roboto Bold'), local('Roboto-Bold'),
    url('./Fonts/roboto-v18-latin-700.eot?#iefix') format('embedded-opentype'),
    url('./Fonts/roboto-v18-latin-700.woff2') format('woff2'),
    url('./Fonts/roboto-v18-latin-700.woff') format('woff'),
    url('./Fonts/roboto-v18-latin-700.ttf') format('truetype'),
    url('./Fonts/roboto-v18-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* gotham-300 - latin */
@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 300;
  src: url('./Fonts/Gotham-Light.otf') format('opentype');
}
/* gotham-400 - latin */
@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 500;
  src: url('./Fonts/Gotham-Book.otf') format('opentype');
}
/* gotham-500 - latin */
@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 600;
  src: url('./Fonts/Gotham-Medium.otf') format('opentype');
}
/* gotham-800 - latin */
@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 700;
  src: url('./Fonts/Gotham-Bold.otf') format('opentype');
}
html,
body {
  min-height: 100%;
}
body {
  font-family: Gotham, Raleway, 'Helvetica Neue', Arial, sans-serif;
  border: 0;
  margin: 0;
  font-size: 14pt !important;
  background-color: var(--background);
  background-size: cover;
  background-position: center center;
}
a {
  transition: all 0.15s ease-in-out;
}
a,
a:visited {
  color: var(--blue) !important;
  text-decoration: none !important;
}
a:hover {
  color: var(--blue-lighter) !important;
  text-decoration: underline !important;
}

header a,
header a:hover {
  text-decoration: none !important;
  color: var(--blue-lighter) !important;
}

footer a,
footer a:visited {
  color: var(--blue-lighter) !important;
}
footer a:hover {
  color: var(--blue-lightest) !important;
  text-decoration: none !important;
}

footer hr {
  border-color: var(--navy-lightest) !important;
}

/* Bootstrap Navigation */

.navbar {
  margin: 0 !important;
  /*
  background-color: rgba(255,255,255,0.5) !important;
  position: absolute !important;
  width: 100%;
  z-index: 200;
  */
  border: none !important;
  border-radius: 0 !important;
}

.navbar-inverse {
  background-color: var(--blue-3) !important;
}
.navbar-inverse .navbar-nav > li > a {
  color: var(--grey);
}
.navbar-inverse .navbar-nav > li > a:hover {
  color: var(--navy-light);
}

.navbar-default {
  background-color: var(--blue-3) !important;
}
.navbar-default .navbar-brand {
  color: var(--grey-lightest) !important;
}
.navbar-default .navbar-nav > li > a {
  color: var(--blue-lightest) !important;
}
.navbar-default .navbar-nav > li > a:hover {
  color: var(--navy-lightest) !important;
}

.navbar-toggle .icon-bar {
  background-color: black !important;
}

.navbar-inverse .navbar-toggle:focus,
.navbar-inverse .navbar-toggle:hover {
  background-color: #ddd !important;
}

.navbar-inverse .navbar-nav > .active > a,
.navbar-inverse .navbar-nav > .active > a:focus,
.navbar-inverse .navbar-nav > .active > a:hover {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
.navbar-inverse .navbar-nav > li > a {
  background-color: none !important;
}

.jumbotron {
  background-color: transparent !important;
}

/** Buttons **/

.btn {
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  opacity: 0.5 !important;
}

.btn-primary {
  background-color: var(--blue-6) !important;
  border-color: var(--blue-6) !important;
}
.btn-primary:hover {
  background-color: var(--blue-5) !important;
  border-color: var(--blue-5) !important;
}

.btn-info {
  background-color: var(--blue-5) !important;
  border-color: var(--blue-5) !important;
}
.btn-info:hover {
  background-color: var(--blue-4) !important;
  border-color: var(--blue-4) !important;
}

/** Downloads Row Wrapper **/
.download-row-wrapper {
  display: flex;
}

@media screen and (max-width: 768px) {
  .download-row-wrapper {
    display: block;
  }
}

/** Login Dialog **/

.slick-active .control-panel-slide {
  transform: translateY(-20px);
}
.slick-active .control-panel-card {
  border-top: 11px solid #4a90e2 !important;
  background-color: white !important;
  opacity: 1 !important;
}
.slick-slide div {
  outline: none;
}
.shadow-hover {
  box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.16) !important;
  transition: all 0.3s ease-in-out;
}
.shadow-hover:hover {
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.32) !important;
}
